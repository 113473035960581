import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  messages: {
    flex: "1",
    backgroundColor: "#fff",
    padding: "10px",
    overflowY: "scroll",
    display: "flex",
    flexDirection: "column"
  },
  waitingContainer: {
    textAlign: "left",
    maxWidth: "100%",
    position: "relative",
    marginBottom: "10px",
  },
  waitingBubble: {
    textAlign: "left",
    color: "#333333",
    fontWeight: "600",
    backgroundColor: "#F4F6F9",
    borderRadius: "26px 26px 26px 3px",
    padding: "12px 20px",
    display: "inline-flex",
    maxWidth: "80%",
    overflowWrap: "break-word",
    lineHeight: "1.4",
    fontSize: "14px",
    position: "relative",
    marginBottom: "3px"
  }
}));

export default useStyles;