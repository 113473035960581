import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import Message from "./Message";
import ContactRequestForm from "./ContactRequestForm";
import TypingIndicator from "./TypingIndicator";
import Loading from "./Loading";
import Delayed from "./Delayed";
import useStyles from "./styles/Messages.styles";
import Skeleton from "@material-ui/lab/Skeleton";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";

/**
 * React component for container of multiple messages
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function Messages(props) {
  // REFERENCE FOR END OF MESSAGE CONTAINER
  const messagesEnd = useRef(null);
  const [showSendTextForm, setShowSendTextForm] = useState(false);
  const [showSendEmailForm, setShowSendEmailForm] = useState(false);
  const [phoneContactType, setPhoneContactType] = useState("sms");
  const [visitorMessagedBeforeAccepted, setVisitorMessagedBeforeAccepted] = useState(false);

  // SCROLL TO MOST RECENT CHAT MESSAGES ON RENDER
  useEffect(() => {
    messagesEnd.current.scrollIntoView({ behavior: "smooth" });
  });

  useEffect(() => {
    props.messages.map(function(message, i) {
      if (message.sent_by_contact == 1) {
        setVisitorMessagedBeforeAccepted(true);
      }
    });
  }, [props.messages]);

  let messages = props.messages.map((message) => (
    <Message key={message.id} message={message} />
  ));

  if (props.isLoadingConversation) {
    messages = (
      <React.Fragment>
        <Skeleton variant="text" height="50px" />
        <Skeleton variant="text" height="50px" />
        <Skeleton variant="text" height="50px" />
      </React.Fragment>
    );
  }

  const classes = useStyles();

  let conversationNotAccepted = null;
  let clientUnavailable = null;
  let waitingOnAgent = null;

  if (
    props.conversation.active == 0 &&
    props.conversation.accepted == 0 &&
    props.isClientAvailable === true
  ) {
    conversationNotAccepted = (
      <React.Fragment>
        <Message
          message={{
            sent: moment().unix(),
            body:
              "Our customer service team is busy helping other customers.\n\n" +
              "Don’t worry, I’m forwarding your message over to customer service.\n\n" +
              "How would you like them to contact you back?",
            sent_by_contact: 0,
          }}
        />
        <div style={{ paddingBottom: "10px" }}>
          <ButtonGroup
            style={{ minHeight: "36px" }}
            fullWidth
            variant="contained"
            color="primary"
            aria-label="contained primary button group"
            disabled={props.smsOptInSubmitting}
          >
            <Button
              onClick={() => {
                setPhoneContactType("sms");
                setShowSendTextForm(true);
              }}
            >
              Text
            </Button>
            <Button
              onClick={() => {
                setPhoneContactType("call");
                setShowSendTextForm(true);
              }}
            >
              Call
            </Button>
            <Button onClick={() => setShowSendEmailForm(true)}>Email</Button>
          </ButtonGroup>
        </div>
      </React.Fragment>
    );
  } else if (
    props.conversation.active == 0 &&
    props.conversation.accepted == 0 &&
    props.isClientAvailable === false
  ) {
    clientUnavailable = (
      <React.Fragment>
        <div>
          <ButtonGroup
            style={{ minHeight: "36px" }}
            fullWidth
            variant="contained"
            color="primary"
            aria-label="contained primary button group"
            disabled={props.smsOptInSubmitting}
          >
            <Button
              onClick={() => {
                setPhoneContactType("sms");
                setShowSendTextForm(true);
              }}
            >
              Text
            </Button>
            <Button
              onClick={() => {
                setPhoneContactType("call");
                setShowSendTextForm(true);
              }}
            >
              Call
            </Button>
            <Button onClick={() => setShowSendEmailForm(true)}>Email</Button>
          </ButtonGroup>
        </div>
      </React.Fragment>
    );
  }

  if (visitorMessagedBeforeAccepted && props.conversation.active && !props.conversation.accepted && props.isClientAvailable) {
    waitingOnAgent = (
      <Delayed waitBeforeShow={4500}>
          <div className={classes.waitingContainer}>
            <div className={classes.waitingBubble}>
              Connecting<Loading/>
            </div>
          </div>
      </Delayed>
    );
  }

  if (
    props.conversation.active == 0 &&
    (showSendEmailForm ||
      showSendTextForm ||
      props.conversation.sms_opt_in ||
      props.conversation.call_opt_in ||
      props.conversation.email_opt_in)
  ) {
    return (
      <React.Fragment>
        <ContactRequestForm
          phoneContactType={phoneContactType}
          conversation={props.conversation}
          showSendTextForm={showSendTextForm}
          setShowSendTextForm={setShowSendTextForm}
          setShowSendEmailForm={setShowSendEmailForm}
          showSendEmailForm={showSendEmailForm}
          smsOptInSubmitting={props.smsOptInSubmitting}
          smsOptIn={props.smsOptIn}
          handleInvalidToken={props.handleInvalidToken}
          clientCountryCode={props.clientCountryCode}
        />
        <div style={{ float: "left", clear: "both" }} ref={messagesEnd}></div>
      </React.Fragment>
    );
  }

  return (
    <div className={classes.messages}>
      {messages}
      {waitingOnAgent}
      {conversationNotAccepted}
      {clientUnavailable}
      {props.showTypingIndicator == true && <TypingIndicator />}
      <div style={{ float: "left", clear: "both" }} ref={messagesEnd}></div>
    </div>
  );
}

export default Messages;
