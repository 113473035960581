import React from "react";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

/**
 * React component rending a Loading indicator.
 */
export default class Loading extends React.Component {
  //other logic
  render() {
    return (
      <Loader
        type="ThreeDots"
        color="#666666"
        height={4}
        width={20}
      />
    );
  }
}